import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import phone from "../assets/img/phone.svg";
import email from "../assets/img/email.svg";
import youtube from "../assets/img/youtube.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={4}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={8} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#">
                <img src={phone} alt="Icon" />
              </a>
              <span>+7 (985) - 226 - 6100</span>
              <a href="#">
                <img src={phone} alt="Icon" />
              </a>
              <span>+7 (916) - 645 - 2891</span>
              <a href="#">
                <img src={email} alt="Icon" />
              </a>
              <span>s4-t@yandex.ru</span>
              <a href="https://youtube.com/channel/UC8SO84ghA9oqM5XkQzXZNXw?si=pVHTKHcfLF7Qz-C3">
                <img src={youtube} alt="Icon" />
              </a>
              <span>Ютуб канал</span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
